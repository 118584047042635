import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { PageLayout, Header } from '../../components';
import { Heading1, Text } from '../../helper/Typography';
import { Button, SimpleImage } from '../../helper/StyledHelper';
const translation = require('../../internationalText/en.json');

export default () => {
  const { general: generalTexts } = translation;
  return (
    <PageLayout
      userLanguage={'en-US'}
      generalText={generalTexts}
      darkTheme={true}
    >
      <main>
        <Header
          darkTheme={false}
          lang={'en'}
          pathname={'/en/404'}
          generalTexts={generalTexts}
        />
        <Flex>
          <div style={{ marginBottom: '20px', width: '80px' }}>
            <SimpleImage src="/assets/icons/poop.svg" />
          </div>
          <Heading1>Oh Crap!</Heading1>

          <Text.p style={{ marginBottom: '48px' }}>
            Sorry, the page you were looking for doesn`t exist.
          </Text.p>
          <Link to="/en/home">
            <Button>Go back to Home</Button>
          </Link>
        </Flex>
      </main>
    </PageLayout>
  );
};

const Flex = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 289px);
  width: 350px;
  margin: auto;
  @media (min-width: 768px) {
    height: calc(100vh - 241px);
    width: 430px;
  }
`;
